import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR camera with PIR and coupled areas (Synology Surveillance Station)",
  "path": "/Frequently_Asked_Question/Synology_coupled_PIR_and_Areas/",
  "dateChanged": "2022-11-11",
  "author": "Mike Polinowski",
  "excerpt": "I have integrated my camera into the Synology Surveillance Station. However, Synology tells me that when areas are coupled, there is no alarm notification to the SSS. How to solve the problem?",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='FAQs - INSTAR camera with PIR and coupled areas (Synology Surveillance Station)' dateChanged='2022-11-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='I have integrated my camera into the Synology Surveillance Station. However, Synology tells me that when areas are coupled, there is no alarm notification to the SSS. How to solve the problem?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Synology_coupled_PIR_and_Areas/' locationFR='/fr/Frequently_Asked_Question/Synology_coupled_PIR_and_Areas/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "instar-camera-with-pir-and-coupled-areas-synology-surveillance-station",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-camera-with-pir-and-coupled-areas-synology-surveillance-station",
        "aria-label": "instar camera with pir and coupled areas synology surveillance station permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR camera with PIR and coupled areas (Synology Surveillance Station)`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I have integrated my camera into the Synology Surveillance Station. However, Synology tells me that when areas are coupled, there is no alarm notification to the SSS. How to solve the problem?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The procedure would be the following:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`First of all, activate the motion detection "Alarm - Areas" in the camera itself, i.e. the web interface, draw at least one area in the image and activate it, we recommend to select several smaller areas distributed over the image where the motion detection should then take place. Then save the whole thing.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Furthermore, in the web interface of the camera under "Alarm - Actions" now activate the PIR as well as the pairing. That in itself is all on the part of the camera, now switch to the Surveillance Station:`}</p>
      </li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`First go "IP Camera" and there right click on the camera to edit it. Then go to "Event detection" on the left side. Select the tab "Motion" and select "By camera" and activate the option "Keep original camera settings".`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9432e8cff35ccb4395004e021af3b95d/e088a/ce30008d65141af4f003da27b4644e77a7a07a8f.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABwklEQVQoz43RTW/TMBjA8XwKGFmT2I/tx4+TkHYloUnWF9YiNip1EwcEPaxCsFZaRdWtVbVpVHu57AJHJL4CX4ETJ74XSisEAg6TfrIs239fHguUluSDIqmNB/I+Qxs0l+iCtF226fE122G2u9oUh+CCdLmwomqcZNuAxhPIFKm4RbWdJM2rj1NJAaABNExq7YeSfIGkTACKmEQm0YrTet7qbCU5BhVAP220d7v7WaOd1nfKcWbKj2yX7b94OVvMThbz+dn5eDo9mZ/2+68FGqsa1/J6M65ligKuNG6lT57uVuJaVE0oLAM93Ci5/cHRxcX1cvnh8tPn6fnl9c3VaHiEJrT08Zfo9kd09c1Zfn+wN65EUZI3dVAWOpAUKgrvOe7p88Ovg+Xi7fDdeDQaT94PB+1mJrRveXHHyXqltOvmB16UCTSCQkHBmqRgw2O9ID/bPnjWaL4ZvFrMJpPj4V6nxaW2AARwvuJxIbkirvSfmNSbDBwQTKLUPpKvyGgTFDFH/zdlVvHfBPpQKG6ZJLZei/h/r/8FitZjK774Nb+7xi4oT+AacM4ZYyDuFAP6cdYox1klyStJZvdmpcOPpVr3Jwhgf1RbROz+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9432e8cff35ccb4395004e021af3b95d/e4706/ce30008d65141af4f003da27b4644e77a7a07a8f.avif 230w", "/en/static/9432e8cff35ccb4395004e021af3b95d/d1af7/ce30008d65141af4f003da27b4644e77a7a07a8f.avif 460w", "/en/static/9432e8cff35ccb4395004e021af3b95d/7f308/ce30008d65141af4f003da27b4644e77a7a07a8f.avif 920w", "/en/static/9432e8cff35ccb4395004e021af3b95d/9e7d9/ce30008d65141af4f003da27b4644e77a7a07a8f.avif 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9432e8cff35ccb4395004e021af3b95d/a0b58/ce30008d65141af4f003da27b4644e77a7a07a8f.webp 230w", "/en/static/9432e8cff35ccb4395004e021af3b95d/bc10c/ce30008d65141af4f003da27b4644e77a7a07a8f.webp 460w", "/en/static/9432e8cff35ccb4395004e021af3b95d/966d8/ce30008d65141af4f003da27b4644e77a7a07a8f.webp 920w", "/en/static/9432e8cff35ccb4395004e021af3b95d/3a6ad/ce30008d65141af4f003da27b4644e77a7a07a8f.webp 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9432e8cff35ccb4395004e021af3b95d/81c8e/ce30008d65141af4f003da27b4644e77a7a07a8f.png 230w", "/en/static/9432e8cff35ccb4395004e021af3b95d/08a84/ce30008d65141af4f003da27b4644e77a7a07a8f.png 460w", "/en/static/9432e8cff35ccb4395004e021af3b95d/c0255/ce30008d65141af4f003da27b4644e77a7a07a8f.png 920w", "/en/static/9432e8cff35ccb4395004e021af3b95d/e088a/ce30008d65141af4f003da27b4644e77a7a07a8f.png 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9432e8cff35ccb4395004e021af3b95d/c0255/ce30008d65141af4f003da27b4644e77a7a07a8f.png",
              "alt": "INSTAR camera with PIR and coupled areas (Synology Surveillance Station)",
              "title": "INSTAR camera with PIR and coupled areas (Synology Surveillance Station)",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Now select the "PIR" tab and choose "By Camera" and enable the "Keep original camera settings" option. What is confusing in this menu is the sentence from Synology at the very bottom... ignore it.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/4b2cc/909363c238fec7d1cab18119df9be4d13a35a830.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACAElEQVQoz12QTU8TURSG51+Itsz9mvs1c+/c6Z1O22mHmbaIXzQhAYyKiQpqAWnBSoySBgts3Ll059L/4MadP8xMUTQkT96cs3jfc/I6HpdUBJ4IqAwApoRJwiSmsoJYBaAKQFWAqxD/HUgF4EVIAKYuIk69lWXdFaktV5EnVL3ZbnVybRsgiDGTeB7kYkq4RFRAj2EmAKEuppAwp5akzawIohhSCalIOt3+nVVVS6S2gUlkaCsQD9Y2JsdH43dvx5PJ7mh0cDja3NzAVDjGmCS2JtQIuBBhbtt5f8U2UttIhY6wDBeq7tOdvZPp2elsdvbl6+Rkdn5xvjt8RYXviPE38/mX/vSjOv15c/mFDvx7g7V0qUizIs1yqaMbVfd48Pz7s+mH4f72/nB4cLS3vbVcdAj3HZiugvyhu7QOeo/dsM2En6SZ1JEf1ngQYioWANw0xWm+fj8vXu48+fh+cvjm9d1+Bj3uYIQwBBgCBF1EPEiFizxA2CWICkDK2gEikFDuKxko4ZdAjzmI+f+gZaV/Sp5zuRLulzOT0OPA43MVZduIiv8BHrs6ewX0OJlnEe6XQTzAXJVvXzPL2Lbyblb023m3k/danTzLe7aRBsbqWqyiWBlrI6MEhwhdN3NfSx0JZaQ2pSrjK0OFqjU62jZNvRnGrVsPRpVHF4v1278BHlCDZ5+Ong0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/e4706/909363c238fec7d1cab18119df9be4d13a35a830.avif 230w", "/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/d1af7/909363c238fec7d1cab18119df9be4d13a35a830.avif 460w", "/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/7f308/909363c238fec7d1cab18119df9be4d13a35a830.avif 920w", "/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/1b9e6/909363c238fec7d1cab18119df9be4d13a35a830.avif 1013w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/a0b58/909363c238fec7d1cab18119df9be4d13a35a830.webp 230w", "/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/bc10c/909363c238fec7d1cab18119df9be4d13a35a830.webp 460w", "/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/966d8/909363c238fec7d1cab18119df9be4d13a35a830.webp 920w", "/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/d5002/909363c238fec7d1cab18119df9be4d13a35a830.webp 1013w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/81c8e/909363c238fec7d1cab18119df9be4d13a35a830.png 230w", "/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/08a84/909363c238fec7d1cab18119df9be4d13a35a830.png 460w", "/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/c0255/909363c238fec7d1cab18119df9be4d13a35a830.png 920w", "/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/4b2cc/909363c238fec7d1cab18119df9be4d13a35a830.png 1013w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dfbcfb75132dc61a567ed5c9ab00ae49/c0255/909363c238fec7d1cab18119df9be4d13a35a830.png",
              "alt": "INSTAR camera with PIR and coupled areas (Synology Surveillance Station)",
              "title": "INSTAR camera with PIR and coupled areas (Synology Surveillance Station)",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Now go to "Recording" on the left side and there to the "Schedule" tab. Select "Adjust 1" and mark all times, these should then appear in the color of the "Adjust 1" schedule. As detection select both the motion detection and the PIR.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/e088a/44b43e818f5606b61d5bda7e091a3b970f964fb8.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACO0lEQVQoz2XOTWvUQBwG8P0KHgS33U0yycxkXpLJZCYvm53NvqS7rbUUwYN4LFT6epLeLHpSUOwqFSuIxeKlx+pBEMEvoCfFLyCCIqhXvcpuL4Lw8D/9fzxPzXMs6FjQbkB7xrIsFxEH+nWXAOQDD0KfQcI8RIDnn5u16g171nIattt0vCbwauzCSnxlpzFarZ/fbOpKJYnMO01hmC5kXhZlL9K5SJPUGCYUFzEk3MXUxRQgUiPrB+zmG3vn5My1V/b8SqGYTHLXhQ4Tsj+MdWYBZGHoR2EQaR4p6FOAfBdTRFgtTZKOaWeJJggi7LdMR7cMwMyGk4+irMpqoVXOqcxwmXp+0HQxQMTFFBJW062yP1osqwWRFACzQOqOybUSUcSlDJjMcNLzlaFCM6GlUokWaFI+xS6mtodtD7vYn3XJsJe83wvf7UUf9+Xb27LT76VmUJhSp7lH4/Gm/vxULXbZDCCYTvHpDA+TGUCXKv3tMPzyJPh5FHx4EGTdubg9L1sDyNOzTvxwW/w5Di72ad2hEwwQOc0Uk6WB+nEUfn8mfj0Pvx6Gr29lL2/IF7vhya442Y0+7Yvfx+LS3H/YRaTpkcKoe2vBnVV+9yofr7GDDf/xFj3Y5vub/NEWG6/x8Tpvp8yCHNOgZiPyT/ysN2gPl81wWXeGnq5sNQTJPNZ9rkuiShqbMCmLVtHKU4j8WsAJw3QyYnrjIEojqaNYiwgFMQgU4DEVca8aFWXPdPtFd+Bdvg5W7jeS0V80jZdoM6aGFwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/e4706/44b43e818f5606b61d5bda7e091a3b970f964fb8.avif 230w", "/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/d1af7/44b43e818f5606b61d5bda7e091a3b970f964fb8.avif 460w", "/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/7f308/44b43e818f5606b61d5bda7e091a3b970f964fb8.avif 920w", "/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/9e7d9/44b43e818f5606b61d5bda7e091a3b970f964fb8.avif 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/a0b58/44b43e818f5606b61d5bda7e091a3b970f964fb8.webp 230w", "/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/bc10c/44b43e818f5606b61d5bda7e091a3b970f964fb8.webp 460w", "/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/966d8/44b43e818f5606b61d5bda7e091a3b970f964fb8.webp 920w", "/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/3a6ad/44b43e818f5606b61d5bda7e091a3b970f964fb8.webp 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/81c8e/44b43e818f5606b61d5bda7e091a3b970f964fb8.png 230w", "/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/08a84/44b43e818f5606b61d5bda7e091a3b970f964fb8.png 460w", "/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/c0255/44b43e818f5606b61d5bda7e091a3b970f964fb8.png 920w", "/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/e088a/44b43e818f5606b61d5bda7e091a3b970f964fb8.png 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5e158eaf7bfb9ad39b1e8504e8850f40/c0255/44b43e818f5606b61d5bda7e091a3b970f964fb8.png",
              "alt": "INSTAR camera with PIR and coupled areas (Synology Surveillance Station)",
              "title": "INSTAR camera with PIR and coupled areas (Synology Surveillance Station)",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Save the whole thing now and that's it. The Synology is now only triggered if the camera itself detects something via the PIR as well as the software detection. If you deactivate the PIR in the camera, but leave the pairing active, no alarm would be triggered, or if the PIR is activated incl. pairing but the areas are not activated, no alarm would be triggered, unless you would also deactivate the pairing in the camera.`}</li>
    </ol>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      